<template>
  <div>
    <v-container class="mt-lg-16 pt-lg-16">
      <v-row>
        <v-col cols="12" lg="5" class="mx-auto">
          <v-card>
            <v-card-title primary-title class="text-h6 pb-lg-0 pt-lg-12 justify-center font-weight-bold"> Sign Up </v-card-title>
            <v-form ref="signup">
              <v-container>
                <v-row>
                  <v-col cols="12" lg="11" class="mx-lg-auto">
                    <v-text-field prepend-icon="mdi-account" label="Company" v-model="signup.tenant"></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="11" class="mx-lg-auto">
                    <v-text-field prepend-icon="mdi-account" label="Email" v-model="signup.email"></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="11" class="mx-lg-auto">
                    <v-text-field prepend-icon="mdi-account" label="Phone" v-model="signup.phone"></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="11" class="mx-lg-auto">
                    <v-text-field prepend-icon="mdi-lock" label="Password" type="password" v-model="signup.password" @keyup.enter="login()"></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="7" class="mx-lg-auto">
                    <v-btn primary large block @click="createAccount()">Create Account</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
            <v-card-actions class="py-lg-7 px-lg-7">
              <p class="primary--text" @click="$router.push('/login')">Back to Login</p>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      signup: {}
    }
  },
  methods: {
    createAccount () {
      let date = new Date()
      date.setDate(date.getDate() + 3)
      this.$_execute('post', `${this.$route.params.tenant || this.signup.tenant}/login`, this.signup)
    }
  }
}
</script>
